<template>
    <div class="language">
        <button @click="setLang('de')" :class="{active: isGerman}">DE</button>
        <span>&nbsp;::&nbsp;</span>
        <button @click="this.setLang('en')" :class="{active: !isGerman}">EN</button>
    </div>

</template>

<script>
import { store } from '@/store.js';

export default{
    name: "languageSelect",
    methods:{
        setLang(lan){
            sessionStorage.setItem('language', lan);
            location.reload();
        }
    },
    computed: {
        isGerman() {
            if (store.language === 'de') {
                return true;
            }
            return false;
        },
    }
}

</script>

<style scoped>
.language, span {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}
.language {
    padding-left: 1rem;
    flex: 0 0 fit-content;
    transition: height 0.5s ease-in-out;
}

span {
    font-size: clamp(0.75rem, 1.5vh, 1.25rem);
    line-height: clamp(0.75rem, 1.5vh, 1.25rem);
}

button {
    font-size: clamp(0.75rem, 1.5vh, 1.25rem);
    line-height: clamp(0.75rem, 1.5vh, 1.25rem);
    background-color: transparent;
    color: slategrey;
    border: none;
    cursor: pointer;
}

button:hover {
    color: fuchsia
}

.active {
    color: fuchsia
}
</style>