<template>
    <div class="fit waves">

        <!-- bubbles on site // only active if has children-->
        <section v-if="this.parent && this.parent.children.length" class="bubbles">
            <dhGalleryBubble v-for="(item,index) in this.parent.children" :ref="`bubble-${item.id}`" :key="item.id" :title="item.title" :slug="'/' + this.parent.slug + '/' + item.slug" :layer=index />
        </section>

        <!-- content pane -->
        <section class="nowater" v-if="this.content">
            <ContentPane ref="ContentPane" :content="this.content"/>
        </section>

    </div>
</template>

<script>
import dhGalleryBubble from '@/components/dhGalleryBubble.vue';
import ContentPane from '@/components/contentPane.vue';
import { store } from '@/store.js'

export default {
    name: 'dhGalleryHome',
    data() {
        return {
            content: null,
            parent: null,
            store
        }
    },
    components:{
        dhGalleryBubble,
        ContentPane
    },
    methods: {
        async fetchPageData() {
          const parentId = this.$route.matched[0].name
          const pageId = this.$route.path.replace('/', '')
          const response = await fetch(`${store.api}?language=${store.language}`, {
            method: "post",
            body: JSON.stringify({
              query: 'site',
              select: {
                parent: {
                  query: `page('${parentId}')`,
                  select: {
                    id: true,
                    slug: true,
                    title: true,
                    children: {
                      query: 'page.children',
                      select: {
                        id: true,
                        title: true,
                        slug: true
                      }
                    }
                  },
                },
                page: {
                  query: `page('${pageId}')`,
                  select: {
                    id: true,
                    title: true,
                    slug: true,
                    vue: true,
                    text: true
                  },
                }
              }
            }),
          });

          const { result } = await response.json();

          this.content = result.page;
          this.parent = result.parent;
            // store.addMenuText(this.content.title.rendered);

            


            //load active
            /*
            var ix = this.children.findIndex(x => x.id == this.$route.query.id);
            store.setContent(this.children[ix]);
            store.addMenuText(this.children[ix].title.rendered);
            */
            
            
        },
    },
    async created() {
        this.fetchPageData();
    }

}
</script>

<style scoped>
.fit{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.bubbles{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 5vh;
}
.nowater {
  max-width: 800px;
  height: fit-content;
  margin: auto;
  background-color: white;
  padding: 1rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


.waves{
  background-image: url("data:image/svg+xml;charset=UTF-8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'><defs><pattern id='wave' patternUnits='userSpaceOnUse' width='70' height='50' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(255,0%,100%,0.5)' /><path transform='translate(0 25)' d='M-.02 22c8.373 0 11.938-4.695 16.32-9.662C20.785 7.258 25.728 2 35 2c9.272 0 14.215 5.258 18.7 10.338C58.082 17.305 61.647 22 70.02 22M-.02 14.002C8.353 14 11.918 9.306 16.3 4.339 20.785-.742 25.728-6 35-6 44.272-6 49.215-.742 53.7 4.339c4.382 4.967 7.947 9.661 16.32 9.664M70 6.004c-8.373-.001-11.918-4.698-16.3-9.665C49.215-8.742 44.272-14 35-14c-9.272 0-14.215 5.258-18.7 10.339C11.918 1.306 8.353 6-.02 6.002' stroke-width='1.5' stroke='hsla(226, 61%, 48%, 0.7)' fill='none' /></pattern></defs><rect width='100%' height='100%' transform='translate(0,0)' fill='url(%23wave)' /></svg>");
  background-repeat: repeat;
}


</style>