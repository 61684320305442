<template>
    <!--list view-->
    <div v-if="content.vue === 'contentlist'" class="pane">
        <div class="infoSection" v-for="item in this.content.lists" :key="item.id">
            <h3 class="infoHeading" v-html="item.headline"></h3>
            <div class="infoEntry" v-for="item2 in item.projects" :key="item2.id">
                <p class="infoYear">{{ item2.year }}</p>
                <p class="infoText">{{ item2.info }}</p>
                <a v-if="item2.link" class="link" v-bind:href="item2.link" target="blank">>>link</a>
            </div>
        </div>
    </div>
    <!--other views-->
    <div v-else class="pane">
        <h3 v-html="content.title"></h3>
        <div v-if="content.list" class="projectInfo" v-html="content.list"></div>
        <div v-if="content.year" class="year"> {{ content.year }}</div>
        <div v-if="content.external" class="linkwrapper">
            <a class="link" v-bind:href="content.external" target="blank">> {{ content.externallabel }}</a>
        </div>
        <div v-if="content.vimeo" class="videowrapper">
            <dh-loader v-show="!this.vimeoLoaded"></dh-loader>
            <vimeo-player v-show="this.vimeoLoaded" ref="player" :options="options" :video-id="content.vimeo" @loaded="isVimeoLoaded"/>
        </div>

        <div v-if="content.youtube" class="videowrapper">
            <dh-loader v-show="!this.youtubeLoaded"></dh-loader>
            <div v-show="this.youtubeLoaded">
            <VueYtframe :video-id="content.youtube" ref="youtube" :player-vars="{ autoplay: 0, listType: 'user_uploads' }" @ready="isYoutubeLoaded" @playing="setSubtitle"/>
            </div>
        </div>
        <div v-if="content.text" class="maintext">{{ content.text }}</div>

        <!--downloads-->
        <div v-if="this.content.download" class="linkwrapper">
            <a class="link" v-bind:href="store.static + this.content.download" target="_blank">> {{ this.content.downloadcaption }}</a>
        </div>


        <!--iterate over images to separate normal images from logos; logos comes last-->
        <div v-for="item,index in modalImages" :key="item.id" role="link" @click="openModal(index)" class="imagepreview">
            <dh-image :image="item" :size-array="'(max-width: 440px) 100vw, (max-width: 768px) 100vw, (max-width: 1400px) 50vw, (min-width: 1400px) 50vw'"></dh-image>
            <p v-if="item.author" class="imgauthor">&copy; {{item.author}}</p>
        </div>
        <!--LOGOS are marked-->
        <div v-for="item in this.logos" :key="item.id">
            <img v-bind:src="store.static + item.id"/>
        </div>

        <!--image modal-->
        <transition name="modal-fade">
            <dh-image-modal v-if="modal" :type="'image'" :source="this.images" :index="this.modalindex" @close-modal="closeModal"></dh-image-modal>
        </transition>
    </div>

</template>

<script>
import dhLoader from '@/components/dhLoader.vue';
import dhImage from '@/components/dhImage.vue';
import dhImageModal from '@/components/dhImageModal.vue';
import {store} from "@/store";

export default {
    name: "contentPane",
    data() { 
        return {
            store,
            vimeoLoaded: false,
            youtubeLoaded: false,
            options: {
                dnt: true
            },
            modal: false,
            modalindex: 0,
            logos: [],
            images: []
        }
    },
    props: {
        content: Object
    },
    computed:{
        modalImages(){
            return this.images
        }
    },
    watch:{
        content(){
            //console.log(this.content);
            this.sortImages();
        }
    },
    mounted() {   
        this.sortImages();
    },
    components: {
        dhLoader,
        dhImage,
        dhImageModal
    },
    methods: {
        sortImages(){
            this.logos = [];
            this.images = [];
            //sort images and logos
            if(this.content.photos){
                for (const p of this.content.photos) { 
                if(p.imgtype === "Logo") this.logos.push(p);
                else this.images.push(p);
                }
            }
        },
        isVimeoLoaded() {
            this.vimeoLoaded = true;
        },
        isYoutubeLoaded() {        
            this.youtubeLoaded = true;
        },
        setSubtitle(){
            if(this.store.language == "de" && this.$refs.youtube.getAnOption('captions', 'tracklist').filter((x) => x.languageCode === 'de-DE')){
                this.$refs.youtube.setAnOption("captions", "track", {languageCode: 'de-DE'});
            }
        },
        openModal(index) {
            this.modalindex = index;
            this.modal = true;
        },
        closeModal() {
            this.modal = false;
        }
    }
}
</script>

<style scoped>
.modal-fade-enter-from,
.modal-fade-leave-to {
opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
transition: opacity .3s ease;
}

.infoSection {
    padding-bottom: 2rem;
}

.infoYear {
    font-weight: 550;
    margin-top: 1rem;
}

.link {
    background: white;
    border: 0.2rem solid white;
    color: black;
    text-decoration: none;
    padding: 0 0.25rem;
    margin-bottom: 1rem;
}

.link:hover {
    color: fuchsia;
}

.link:active {
    color: black;
}

:deep(ul) {
    list-style: none;
}

:deep(li) {
    margin-bottom: 0.0em;
}

:deep(iframe){
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
}

:deep(.vp-center){
    justify-content: flex-start ;
}

.year{
    margin-bottom: 20px;
}

.maintext{
    white-space: pre-wrap;
    margin-bottom: 15px;
    padding-top: 20px;
}

img{
    width: 100%;
}

.imgauthor{
    font-size: x-small;
}

.videowrapper{
    padding-top: 20px;
}

.imagepreview {
    width: 100%;
    padding-top: 1rem;
}

.imagepreview:hover{
    cursor: pointer;
}
        

</style>