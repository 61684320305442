<template>
    <div class="particlePosition" :style="calcPosition()">
        <div class="particleColor">
        </div>
    </div>
</template>

<script>
export default{
  name:"dhParticle",
  props: {
    content: Object
  }, 
  methods:{
    calcPosition() {
      return {
        top : this.content.position.y + 'px',
        left: this.content.position.x + 'px',
        opacity: this.content.opacity
      }
    }
  }
}
</script>

<style>
.particlePosition{
  display: inline-block;
  position: absolute;
  margin: 0px;
  pointer-events: none;
}

.particleColor{
  width: 20px;
  height: 20px;
  background: radial-gradient(circle at 50% 50%, rgba(240, 77, 234, 0.8) 0%, rgba(255,0,0,0) 50%) center no-repeat; 
  pointer-events: none;
}
</style>