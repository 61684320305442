<template>
    <div class="modal">

        <div class="modalContent" v-touch:swipe="swipeHandler">
            <Transition v-bind:name="transitionName" mode="out-in">
                <component :is="'dhImage'" :image="source[liveindex]" :key="source[liveindex].id"/>
            </Transition>
        </div>

        <div class="controls">
          <div class="closeButton" v-html="store.cross" @click="$emit('closeModal')"></div>
          
          <div class="imageControls">
            <button :class="{hidden: this.isFirst}" class="controlBtn" v-html="store.arrowleft" @click="changeImage(-1)"></button>
            <span class="counter white">{{this.liveindex + 1}} / {{ this.source.length }}</span>
            <button :class="{hidden: this.isLast}" class="controlBtn" v-html="store.arrowright" @click="changeImage(1)"></button>
          </div>
      </div>
    </div>
</template>

<script>
import dhImage from "@/components/dhImage.vue";
import { store } from '@/store.js';

export default {
    name: 'dhModal',
    data() {
        return {
            store,
            liveindex: 0,
            forward: true,
        }
    },  
    props: {
        source: [Object],
        index: Number
    },
    components: {
        dhImage
    },
    beforeMount(){
        this.liveindex = this.index;
    },
    methods: {
        changeImage(num){
            this.forward = (num > 0) ? true : false;
            this.liveindex += num;
        },
        swipeHandler(direction) { // 
            if(direction === "right" && !this.isFirst) this.changeImage(-1);
            else if(direction === "left" && !this.isLast) this.changeImage(1);
        }
    },
    computed: {
        isFirst(){
            return this.liveindex == 0;
        },
        isLast(){
            return this.liveindex == this.source.length - 1;
        },
        transitionName() {
            return this.forward ? 'image-fade-fw' : 'image-fade-bw'
        },
    }
}
</script>

<style scoped>

.modal {
  width: 100vw;
  width: 100svw;
  height: 100vh;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  padding: 1rem;
  z-index: 1;
}

.modalContent {
    max-height: 80%;
}

.closeButton {
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  padding: 0.25rem;
  background: mediumspringgreen;
  border: 0.2rem solid mediumspringgreen;
  outline: none;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  cursor: pointer;
}

.controls {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-right: 2.5rem;
    padding-top: 1rem;
}
  
.imageControls {
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0 1rem;
}
  
.controlBtn {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    padding: 0.25rem;
    background: mediumspringgreen;
    border: 0.2rem solid mediumspringgreen;
    outline: none;
    border-radius: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    cursor: pointer;
}
  
.counter {
    min-width: 20vw;
    margin: 0 0.5rem;
    text-align: center;
}
  
.hidden {
    visibility: hidden;
}

.white{
    color: white;
}
</style>