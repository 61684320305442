<template>
    <div v-if="ready" class="intro" :class="[gradients[gradientNo], { maximized: active, minimized: !active }]" @click="activate" v-touch:swipe="swipeHandler">
      
      <div class="plantframe">
        <Transition v-bind:name="transitionName" mode="out-in">
           <component :is="'dhImage'"
            :image="content.files[liveindex]" 
            :srcset="content.files[liveindex].srcset" 
            :size-array="'(max-width: 440px) 440px, (max-width: 768px) 768px, 50vw'" 
            @image-loaded="timer(interval)"
            :key="content.files[liveindex].id" />
        </Transition>
      </div>

      <div class="plantname" :class="{ nogradient: !active }">
        <Transition v-bind:name="transitionName" mode="out-in">
          <span v-html="currentPlantName" :key="content.files[liveindex].id"></span>
        </Transition>
      </div>

    </div>
</template>

<script>
import dhImage from '@/components/dhImage.vue';
import { store } from '@/store.js';

export default {
    name: 'dhIntro',
    head() {
      return {
        title: store.defaultTitle
      }
    },
    data() {
        return {
          store,
          ready: false,
          content: null,
          liveindex: 0,
          forward: true,
          interval: 10000,
          plantTimeout: null,
          gradients: [
            'to-white',
            'to-black',
            'to-blue',
            'to-violet',
            'to-green'
          ],
          gradientNo: 0,
        }
    },
    components: {
      dhImage,
    },
    async created() {
        this.fetchIntroData();
    },
    methods: {
      activate() {
        this.$router.push("/");
      },
      changeImage(num){
          this.forward = (num > 0) ? true : false;
          if(this.isLast && num > 0) this.liveindex = 0;
          else if(this.isFirst && num < 0) this.liveindex = this.content.files.length - 1;
          else this.liveindex += num;
      },
      swipeHandler(direction) { // 
        if(this.active){
          if(direction === "right") this.changeImage(-1);
          else if(direction === "left") this.changeImage(1);
          clearTimeout(this.plantTimeout);
        }
      },
      timer(time) {
        this.plantTimeout = setTimeout(() => {
          this.changeImage(1);
        }, time);
      },
      async fetchIntroData() {
            const response = await fetch(`${store.api}?language=${store.language}`, {
                method: "post",
                body: JSON.stringify({
                    query: "page('intro')",
                    select: {
                      lists: {
                        query: `page.plants.toStructure`,
                        select: {
                          name_latin : true,
                          name: true,
                          files: true
                        }
                      },
                      files: {
                        query: `page.images`,
                        select: {
                          id: true,
                          imgtype: true,
                          thumb: true,
                          srcset: true,
                          filename: true
                        }
                      },
                    }
                })
            });
            this.content = (await response.json()).result;
            //console.log(this.content);
            this.ready = true;
      }
    },
    computed: {
        isFirst(){
            return this.liveindex == 0;
        },
        isLast(){
            return this.liveindex == this.content.files.length - 1;
        },
        transitionName() {
            return this.forward ? 'image-fade-fw' : 'image-fade-bw';
        },
        currentPlantName(){
          return this.content.lists[this.liveindex].name + ' (' + this.content.lists[this.liveindex].name_latin + ')'
        }
    },
    props: {
      active: Boolean
    },
    mounted() {
      setInterval(() => {
        this.gradientNo = Math.floor(Math.random() * this.gradients.length);
      }, 8000);
    }
}
</script>

<style scoped>
.intro {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  overflow: hidden;
  cursor: pointer;
  background: linear-gradient(to top, fuchsia, transparent);
  background-color: mediumspringgreen;
  transition: height 0.5s ease-in-out, background-color 4s ease-in-out; /* make sure the ease time is set to half the time of the setInteval function in the mounted area */
}

.plantname{
  height: 5vh;
  flex: 0 0 5vh;
  width: 100%;
}

.nogradient {
  background-color: fuchsia;
}

.plantname, .plantframe {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.plantname > span {
  color: rgb(255, 255, 255);
  font-size: clamp(9px, 1rem, 12px);
  font-style: italic;
}

.plantframe {
  width: 100%;
  flex: 0 0 calc(100% - 5vh);
  padding: 0.5rem;
}

img {
  width: auto;
  height: 100%;
  margin: auto;
  display: block;
}

/* Gadients */
.to-white {
  background-color: white;
}

.to-black {
  background-color: black;
}

.to-blue {
  background-color: #3053c5;
}

.to-violet {
  background-color: darkmagenta;
}

.to-green {
  background-color: mediumspringgreen;
}
</style>