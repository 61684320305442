<template>
    <h1>
        <!--
        <router-link v-if="(this.template=='plant-encounters')" :to="{ name: 'Encounter', params: {slug: this.slug }, query: { id: this.id }}" >
            <span v-html="text"></span>
        </router-link>
        -->
        <router-link class="menuLink" :to="this.slug" >
            <span v-html="text"></span>
        </router-link>
    </h1>
</template>

<script>

export default {
    name: 'menuLink',
    props: {
        text: String,
        id: Number,
        slug: String,
        template: String
    },
    methods:{
        loadContent(){
            //console.log(this.link);
        }
    }
}
</script>

<style scoped>
h1 {
    text-align: center;    
}

.menuLink {
    color: fuchsia;
}

.menuLink:link {
  text-decoration: none;
}

.menuLink:visited {
  text-decoration: none;
}

.menuLink:hover {
  text-decoration: underline;
}

.menuLink:active {
  text-decoration: underline;
}

</style>