<template>
  <div>
    <dh-intro :active="this.$route.name === 'Home'"></dh-intro>
    <dh-menu :active="this.$route.name === 'Menu'" :routes='this.pages'></dh-menu>

    <dh-loader v-if="!this.pages"></dh-loader>

    <div v-else ref="contentWrapper" id="contentWrapper" :class="{ maximized: this.contentVisible, minimized: !this.contentVisible }" @scroll="handleScroll" @click="goBack">
      <dh-water id="water"></dh-water>
      <router-view v-if="this.pages" v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'zero'" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>

    <div class="scrollup" :class="{vis: isScrolled}" @click="scrollToTop">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/>
      </svg>
    </div>


  </div>
</template>
  
<script>
import {store} from "@/store";

// Components
import dhIntro from '@/components/dhIntro.vue';
import dhMenu from '@/components/dhMenu.vue';
import dhWater from '@/components/dhWater.vue';
import projectView from '@/components/dhContent';
import projectHomeView from '@/components/dhContent';
import galleryHomeView from '@/components/dhGalleryHome';
import dhGallery from '@/components/dhGallery';
import dhLoader from '@/components/dhLoader.vue';

export default {
  name: 'App',
  metaInfo: {
    title: store.defaultTitle
  },
  components: {
    dhIntro,
    dhMenu,
    dhWater,
    dhLoader,
  },
  data() {
    return {
      pages: null,
      scrollTop: 0
    }
  },
  methods:{
    goBack(e) {
      if (e.target.classList.contains('minimized')) {
        this.$router.back();
      }
    },
    scrollToTop() {
      const myDiv = this.$refs.contentWrapper;
      const scrollDuration = 200; // ms
      
      let scrollStep = -myDiv.scrollTop / (scrollDuration / 15);
      
      let scrollInterval = setInterval(() => {
        if (myDiv.scrollTop != 0) {
          myDiv.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
    },
    handleScroll (e) {
      this.scrollTop = e.target.scrollTop
    }
  },
  computed: {
    contentVisible() {
      return !['Home', 'Menu'].includes(this.$route.name)
    },
    isScrolled() {
      return (this.scrollTop > window.innerHeight * 0.5);
    }
  },
  async mounted() {
    const views = {
      project: projectView, //this.components.projectView,
      projecthome: projectHomeView, // this.components.projectHomeView
      galleryhome: galleryHomeView,
      gallery: dhGallery,
      contenthome:  projectHomeView,
      content: projectView
    }

    const response = await fetch(`${store.api}?language=${store.language}`, {
      method: "post",
      body: JSON.stringify({
                  query: "site.children.listed",
                  select: {
                    id: true,
                    title: true,
                    slug: true,
                    vue: true,
                    isDraft: true,
                    isListed: true,
                    htmltitle: true
                },
      }),
    });

    
    //because await is in brackets the .result is not based on the promise but on the real data
    let pages = (await response.json()).result;
    //console.log(pages);

    //if(this.$drafts) pages = pages.filter(x => x.isListed || x.isDraft === true);
    //else pages = pages.filter(x => x.isListed === true);
   
    //store imprint content in store.js
    pages.forEach(page => {
        // add routes for galleries (subpages of gallery home)
        if (page.vue === 'galleryhome') {
          this.$router.addRoute(
              {
                path: `/${page.slug}/:subpage`,
                name: page.slug + '/',
                component: views["gallery"],
                meta: {transition: "galleryfade", title: `${page.htmltitle}`}
              }
          )
        }

        // add routes for projects (subpages of projects home)
        if (page.vue === 'projecthome') {
          this.$router.addRoute(
              {
                path: `/${page.slug}/:subpage`,
                name: page.slug + '/',
                component: views["project"],
                meta: {title: `${page.htmltitle}`}
              }
          )
        }

        // add routes for content (subpages of projects home)
        if (page.vue === 'contenthome') {
          this.$router.addRoute(
              {
                path: `/${page.slug}/:subpage`,
                name: page.slug + '/',
                component: views["project"]
              }
          )
        }

        //add all routes
        this.$router.addRoute(
          {
            path: `/${page.slug}`,
            name: page.slug,
            component: views[page.vue],
            meta: {title: `${page.htmltitle}`}
          }
        );
    })

    this.$router.replace(this.$route.path)

    // add specific fade when coming back from gallery
    this.$router.beforeEach((to, from) => {
      //console.log(to,from);
      if(from.path.split('/').length > 1 && to.path === "/plant-encounters/") to.meta.transition = "galleryfade";
    })

    // this.$router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    //  nextTick(() => {
        // console.log(to);
        // document.title = to.meta.title || store.defaultTitle;
    // });
  // });


    this.pages = pages;
    }
  }
</script>

<style>
#app {
  width: 100vw;
  width: 100svw;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
}

#contentWrapper {
  width: 100%;
  transition: height 0.5s ease-in-out;
  position: relative;
  background: linear-gradient(to bottom, mediumspringgreen 0%, white 100%);
  overflow-x: hidden;
}

#contentWrapper.minimized {
  cursor: pointer;
}

@keyframes loading {
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.zero-enter-active,
.zero-leave-active {
  transition: all 0.0s;
}

.zero-enter-from,
.zero-leave-to {
  opacity: 0;
}

.galleryfade-enter-active,
.galleryfade-leave-active {
  transition: all 0.5s;
}

.galleryfade-enter-from,
.galleryfade-leave-to {
  transform: translateY(250px);
  opacity: 0;
}

.vis {
  opacity: 1 !important;
  pointer-events: all !important;
}

.scrollup {
  background: mediumspringgreen;
  width: clamp(2rem, 8vh, 4rem);
  height: clamp(2rem, 8vh, 4rem);
  min-width: 2.5rem;
  min-height: 2.5rem;
  padding: 0.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center; 
  justify-content: center;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  opacity: 0;
  transition: all 0.5s;
  cursor: pointer; 
  pointer-events: none;
}

.scrollup svg {
  width: clamp(1.5rem, 2.5vh, 3rem);
  height: clamp(1.5rem, 2.5vh, 3rem);
}


</style>