import { createRouter, createWebHistory } from 'vue-router'
import notFound from "@/components/notFound";

const routes = [
  {
    path: "/",
    name: "Home",
    component: null,
  },
  {
    path: "/menu",
    name: "Menu",
    component: null,
  },
  { 
    path: '/:catchAll(.*)',
    name: 'CatchAll',
    component: notFound,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});


export default router;