<template>
    <div v-if="content" class="imprint">
        <h3>{{ content.title }}</h3>
        <section class="credentials">
            <p>{{ content.name }}<br/>{{ content.street }}<br/>{{ content.location }}</p>
            <p>{{ content.mail }}<br/>{{ content.phone }}</p>
        </section>
        <section class="privacy">
            <h4>{{ headings.privacy[store.language] }}</h4>
            <p>{{ content.privacy }}</p>
        </section>
        <section class="cookies">
            <h4>{{ headings.cookies }}</h4>
            <p>{{ content.cookies }}</p>
        </section>
    </div>
</template>

<script>
import { store } from '@/store.js';

export default {
    name: 'dhImprint',
    data() {
        return {
            store,
            content: null,
            headings: {
                privacy: {
                    'en': 'General information on data protection',
                    'de': 'Allgemeine Angaben zum Datenschutz'
                },
                cookies: 'Cookies'
            }
        }
    },
    methods: {
        async fetchImprintData() {
            const response = await fetch(`${store.api}?language=${store.language}`, {
                method: "post",
                body: JSON.stringify({
                    query: "page('imprint')",
                    select: {
                        id: true,
                        title: true,
                        slug: true,
                        vue: true,
                        name: true,
                        street: true,
                        location: true,
                        mail: true,
                        phone: true,
                        privacy: true,
                        cookies: true
                    }
                })
            });
            this.content = (await response.json()).result;
        }
    },
    async mounted() {
        this.fetchImprintData();
    }
}
</script>

<style scoped>
.imprint {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    background: white;
    width: auto;
    max-width: 800px;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    padding: 1rem
}
</style>