<template>
  <nav>
    <menuLink v-for="item, index in this.routes" :key="index" :text="item.title" :id="index" :slug="item.slug"
              :template="item.vue"/>
  </nav>
</template>

<script>
import menuLink from '@/components/menuLink.vue';

export default {
  name: 'dhNavigation',
  components: {
    menuLink
  },
  props: ['routes']
}
</script>

<style scoped>

</style>