<template>
    <router-link :to="this.slug">
        <div ref="circle" class="circle circleSize b1" role="link">
            <p>{{this.title}}</p>
        </div>
    </router-link>
</template>


<script>

export default{
  name: "dhGalleryBubble",
  props: {
    title: String,
    slug: String,
    layer: Number
  },
}
</script>


<style scoped>


p{
  width: 50%;
  text-align: center;
  overflow: visible;
  display: flex;
  justify-content: center;
}

a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}
.circle {
  display: inline-block;
  display: flex;
  flex-direction: column;
  justify-content: center;  /* Centering y-axis */
  align-items :center;
  clip-path: circle(50px at center);

  font-family: Avenir LT Std;
  line-height: 150%;
  white-space: pre-wrap;

  transition: all .2s ease-in-out;
}

.leafImage{
  pointer-events: none;
  height: 60%;
}

.unClickable{
  pointer-events: none;
}

.circleSize{
  width: 120px;
	height: 120px;
}


.circleTransition{
  transition: 0.3s;
}

.circle:not(.nohover):hover{
  transform: scale(1);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(248, 180, 242, 1) 40%, mediumspringgreen 100%)
}


.b1{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(248, 180, 242, 1) 40%, rgba(250,0,225,1) 100%)
  
}

.b2{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(248, 180, 242, 1) 40%, rgba(250,0,225,1) 100%)
}

[role="link"]:hover {
  cursor: pointer;
}

</style>