<template>
    <div class="wrapper" v-if="this.content">   
      <h3 class="headline">{{ this.content.title }}</h3>

      <div class="image" v-touch:swipe="swipeHandler">
        <Transition v-bind:name="transitionName" mode="out-in">
          <component :is="activeComponent" :class="{ galleryImage: currentDisplay }" :text="content.text" :image="this.content.photos[this.currentImage]" :key="this.content.photos[this.currentImage].id" :suppress="true"/>
        </Transition>
      </div>

      <div class="controls">
          <router-link :to="'/' + this.$route.name" class="controlBtn" tag="div" v-html="store.cross"></router-link>
          
          <div class="imageControls">
            <button :class="{hidden: this.isFirst}" class="controlBtn" v-html="store.arrowleft" @click="changeImage(-1)"></button>

            <span class="counter">{{this.currentDisplay}} / {{ this.content.photos.length }}</span>

            <button :class="{hidden: this.isLast}" class="controlBtn" v-html="store.arrowright" @click="changeImage(1)"></button>
          
          </div>
      </div>
    </div>
</template>

<script>
import dhGalleryText from '@/components/dhGalleryText.vue';
import dhImage from "@/components/dhImage.vue";
import {store} from "@/store";

export default{
  name: "dhGallery",
  data() { 
    return {
      content: null,
      currentDisplay: 0,
      forward: true,
      store
    }
  },
  components: {
    dhImage,
    dhGalleryText,
  },
  methods:{
    changeImage(num){
      this.forward = (num > 0) ? true : false;
      this.currentDisplay += num;
      //noch nicht ganz fertige caching methode
      /*
      //cache previous image
      if(this.currentImage > 0) this.content.photos[this.currentImage - 1]
      //cache next image
      if(this.currentImage < this.content.photos.length) this.content.photos[this.currentImage + 1]
      */
    },
    cacheImage(image){
      const temp = new Image();
      temp.srcset = image.srcset;
      temp.sizes = store.thumbSizes; //image.sizes;
      temp.src = image.src;
    },
    swipeHandler(direction) { // 
      if(direction === "right" && !this.isFirst) this.changeImage(-1);
      else if(direction === "left" && !this.isLast) this.changeImage(1);
    }
  },
  computed: {
    isFirst(){
      return this.currentDisplay == 0;
    },
    isLast(){
      return this.currentDisplay == this.content.photos.length;
    },
    transitionName() {
      return this.forward ? 'image-fade-fw' : 'image-fade-bw'
    },
    activeComponent() {
      return this.currentDisplay == 0 ? 'dhGalleryText' : 'dhImage';
    },
    currentImage() {
      return this.currentDisplay > 0 ? this.currentDisplay - 1 : 0;
    }
  },
  async created(){

      const pageId = this.$route.path.replace('/', '')
      const response = await fetch(`${store.api}?language=${store.language}`, {
        method: "post",
        body: JSON.stringify({
          query: `page('${pageId}')`,
          select: {
            id: true,
            title: true,
            slug: true,
            vue: true,
            text: true,
            photos: {
              query: `page.images`,
              select: {
                id: true,
                thumb: true,
                srcset: true
              }
            }
          },
        }),
      });

      const { result } = await response.json();
      this.content = result;
      //console.log(this.content);
    }
}

</script>

<style scoped>
.wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  padding: 1rem;
}
.headline{
  height: 15%;
}

.headline, .image, .controls {
  flex: 0 0 fit-content;
  text-align: center;
}

.headline, .image {
  text-align: center;
}

.image {
    max-height: 50vh;
  }

.galleryImage {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 auto;
}

.controls {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-right: 2.5rem;
    margin-top: 1rem;
}

.imageControls {
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0 1rem;
}
  
.controlBtn {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    padding: 0.25rem;
    background: mediumspringgreen;
    border: 0.2rem solid mediumspringgreen;
    outline: none;
    border-radius: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    cursor: pointer;
}

.counter {
    min-width: 20vw;
    margin: 0 0.5rem;
    text-align: center;
}
  
.hidden {
    visibility: hidden;
}

@media screen and (min-width: 480px) and (max-width: 768px) and (orientation: landscape) {
  .wrapper {
    justify-content: flex-start;
  }
  .counter {
    min-width: 10vw;
  }
}
</style>