<template>
    <div class="modal" @click="$emit('closeModal')">
        <div class="modalContent">
            <dh-imprint></dh-imprint>
            <div class="closeButton" v-html="store.cross" @click="$emit('closeModal')"></div>
        </div>
    </div>
</template>

<script>
import dhImprint from '@/components/dhImprint.vue';
import { store } from '@/store.js';

export default {
    name: 'dhImprintModal',
    data() {
        return {
            store,
        }
    },
    components: {
        dhImprint,
    }
}
</script>

<style scoped>
.modal {
  width: 100vw;
  width: 100svw;
  height: 100vh;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.modalContent {
    position: relative;
    width: auto;
    max-width: 90%;
    height: auto;
    max-height: 90vh;
}

.closeButton {
  position: absolute;
  bottom: -3rem;
  left: calc(50% - 1.25rem);
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  padding: 0.25rem;
  background: mediumspringgreen;
  border: 0.2rem solid mediumspringgreen;
  outline: none;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  cursor: pointer;
}
</style>