<template>
  <div>
    Not found: {{ this.$route.path }}
  </div>
</template>

<script>
export default {
  name: "notFound"
}
</script>

<style scoped>

</style>