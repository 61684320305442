<template>
  <div class="frame">
    <router-link :to="this.slug" >
      <div class="circle" :class="{ 'b1': active, 'b2': !active }" :style="{ 'animation-delay': this.getDelay() + 's' }"></div>
      <div class="annotationframe" :style="{ 'animation-delay': this.getDelay() + 's' }">
        <svg viewBox="0 0 100 100">
          <defs>

            <path id="circle"
              d="
                M 50, 50
                m -37 0
                a 37 37 0 0 1 74,0
                a 37 37 0 0 1 -74,0"/>
          </defs>
          <text>
            <textPath class="annotationtext" xlink:href="#circle">
              {{ this.getShortTitle() }}
            </textPath>
          </text>
        </svg>
        </div>
    </router-link>
  </div>
</template>


<script>
export default{
  name:"dhContentBubble",
  props: {
    slug: String,
    htmltitle: String,
    layer: Number,
    active: Boolean,
    id: Number,
    title: String
  },
  methods:{
    getDelay(){
      return 0.2 + this.id * 0.05;
    },
    getShortTitle(){
      return this.title.split(' - ')[0].split(':')[0];
    }
  },
  computed: {
    getPageMeta(){
      return {
        title: this.htmltitle
      };
    }
  }
}
</script>


<style scoped>
.frame{
  position: relative;
}
.annotationframe{
  position:absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: opacityintext 0.7s ease-in-out;
  animation-fill-mode: forwards;  
  pointer-events: none;
}

svg {
  width: 100%;
  height: 100%;
}

.annotationtext{
  font-size: 13px;
  fill: fuchsia;
  
}

.circle {
  width: clamp(56px, 10vw, 120px);
  height: clamp(56px, 10vw, 120px);
  display: flex;
  flex-direction: row;
  justify-content: center;  /* Centering y-axis */
  align-items :center;
  animation: scaleinbubble 0.1s ease-in-out;
  animation-fill-mode: forwards;  
  scale: 0;
}

@keyframes scaleinbubble {
  from {
    scale: 0;
  }

  to {
    scale: 1.5;
  }
}
@keyframes opacityintext {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.leafImage{
  pointer-events: none;
  height: 60%;
}

.unClickable{
  pointer-events: none;
}

.circleTransition{
  transition: 0.3s;
}

.nohover{
  background:white;
}

.circle:not(.nohover):hover{
  background: radial-gradient(circle at 50% 50%, rgba(240, 77, 234, 0.8) 0%, rgba(182, 243, 206, 0.7) 20%, rgba(255,0,0,0) 40%) center no-repeat; 
}

.b2 {
  background: radial-gradient(circle at 50% 50%, rgba(182, 243, 206, 1) 0%, rgba(240, 77, 234, 0.4) 15%,rgba(240, 77, 234, 0.5) 20%, rgba(255,0,0,0) 30%) center no-repeat;  
}

.b1 {
  background: radial-gradient(circle at 50% 50%, rgb(255, 255, 255) 0%, rgba(0, 0, 0, 0.4) 15%,rgba(240, 77, 234, 0.8) 20%, rgba(255,0,0,0) 30%) center no-repeat;  
}
</style>