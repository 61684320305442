<template>
    <div ref="scrollContent" id="content" v-if="ready">
      <section v-if="this.parent.children.length" class="bubbleNavigation">
        <!-- only active if has children -->
        <nav class="bubbles">
          <dhContentBubble :slug="'/' + this.parent.slug" :ref="'bubble'" :active="!this.$route.params.subpage" @mouseenter="enterMouse('bubble')" :title="parent.title" :id="0" :htmltitle="this.parent.htmltitle"/>
          <dhContentBubble v-for="(item,index) in this.parent.children" :ref="`bubble${index}`" :active="this.$route.params.subpage === item.slug" :key="item.id" :slug="'/' + this.parent.slug + '/' + item.slug" :title="item.title" :htmltitle="item.htmltitle" :id="index + 1" :layer=index @mouseenter="enterMouse(`bubble${index}`)" />
        </nav>
      </section>

      <section v-else class="emptyColumn"></section>

      <section class="nowater">    
        <ContentPane ref="ContentPane" :content="this.content" />
      </section>

      <dhParticles ref="particles" :emitPos ="hoverEmitter" ></dhParticles>

     
    </div>
</template>

<script>
import dhContentBubble from '@/components/dhContentBubble.vue';
import ContentPane from '@/components/contentPane.vue';
import dhParticles from '@/components/dhParticles.vue';
import { store } from '@/store.js';
import { useHead } from "@unhead/vue";


export default {
    name: 'dhContent',
    data() {
        return {
            ready: false,
            content: null,
            parent: null,
            subpage: null,
            store,
            hoverEmitter: [0,0]
        }
    },
    components:{
        dhContentBubble,
        ContentPane,
        dhParticles
    },
    methods: {
        async fetchPageData() {
          //get main page
          const parentId = this.$route.matched[0].name;
          const pageId = this.$route.path.replace('/', '');
          const response = await fetch(`${store.api}?language=${store.language}`, {
            method: "post",
            body: JSON.stringify({
              query: 'site',
              select: {
                parent: {
                  query: `page('${parentId}')`,
                  select: {
                    id: true,
                    slug: true,
                    title: true,
                    htmltitle: true,
                    children: {
                      query: 'page.children',
                      select: {
                        id: true,
                        title: true,
                        htmltitle: true,
                        slug: true
                      }
                    }
                  },
                },
                page: {
                  query: `page('${pageId}')`,
                  select: {
                    id: true,
                    title: true,
                    htmltitle: true,
                    slug: true,
                    vue: true,
                    text: true,
                    list: true,
                    year: true,
                    external: true,
                    download: true,
                    downloadcaption: true,
                    externallabel: true,
                    vimeo: true,
                    youtube: true,
                    photos: {
                      query: `page.images.sortBy('sort')`,
                      select: {
                        id: true,
                        imgtype: true,
                        author: true,
                        thumb: true,
                        srcset: true,
                        filename: true
                      }
                    },
                    lists: {
                      query: `page.lists.toStructure`,
                      select: {
                        headline : true,
                        projects : {
                          query: `structureItem.projects.ToStructure`,
                          select: {
                            year: true,
                            htmltitle: true,
                            info: true,
                            link: true
                          }
                        }
                      }
                    }
                  }
                }
              }
            })
          });

          const { result } = await response.json();
          this.content = result.page;
          
          this.parent = result.parent;
          //console.log(this.parent.children);
          this.ready = true;
        },
        enterMouse(ele) {
          let targ = null;
          if(Array.isArray(this.$refs[ele])) targ = this.$refs[ele][0].$el;
          else targ = this.$refs[ele].$el;
      
          const rect = targ.getBoundingClientRect();
          const x = (rect.left + rect.right) / 2.;
          const y = (rect.top + rect.bottom) / 2.; 
          this.hoverEmitter = [x,y];
        }
    },
    async created() {
      await this.fetchPageData();

      useHead({
        title: () => {
          return this.content.title
        }
      })
    },
    watch: {
      '$route.params.subpage'(newValue) {
        this.subpage = newValue
        this.fetchPageData()
      }
    }
}
</script>

<style scoped>
#content {
  width: 100%;
  height: 100%;
  max-width: 1024px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0 auto;
}

.bubbleNavigation, .nowater {
  height: 100%;
  flex: 0 0 50%;
  padding: 1rem;
}

.emptyColumn {
  height: 100%;
  flex: 1 0 fit-content;
}

.bubbles{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 25px;
}

.nowater {
  min-height: 100%;
  height: fit-content;
  padding: 1rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Media Query to set column layout at the breaking point for all devices less equal to oldschool iPads */
@media screen and (max-width: 768px) {
  #content {
    flex-flow: column nowrap;
  }

  .bubbleNavigation, .nowater {
  flex: 0 0 fit-content;
}
}
</style>