<template>
    <div class="particle-container" ref="particleContainer">
        <dhParticle v-for="(item,index) in this.particles" :key="index" :content="item"></dhParticle>
    </div>
</template>

<script>
import { easeOutExpo } from '@/helper.js';
import dhParticle from '@/components/dhParticle.vue';

export default {
    name: "particleSystem",
    props: ['emitPos'],
    components:{
        dhParticle
    },
    data(){
        return{
            //particles
            currentOver: null,
            particles: [],
            //deltatime
            lastUpdate: Date.now(),
            delta: 0,
            now: 0,
            //physics
            gravity: 9.81

        }
    },
    watch: { 
        emitPos: function(newVal) { // watch it
          //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
          this.addParticles(newVal);
          }
        },
    methods: {
        animate(){
            //calculate delta time
            this.now = Date.now();
            this.delta = (this.now - this.lastUpdate);
            this.lastUpdate = this.now;
            
            //animate
            if(this.particles.length > 0){
                this.animateParticles();
            }

            //call next frame
            window.requestAnimationFrame(this.animate);
        },
        addParticles(newPos){
            let x = window.scrollX + newPos[0] - this.$refs.particleContainer.getBoundingClientRect().left;
            let y = window.scrollY + newPos[1] - this.$refs.particleContainer.getBoundingClientRect().top;
            var count = Math.floor(Math.random() * 5) + 5;
            for(var j = 0; j < count; j++){
                var vX = (Math.random() - 0.5) * 0.2;
                var vY = Math.random() * 0.2;
                var p = {position: {x,y}, velocity: {vX,vY}, lifeTime: 1000 + (Math.random() * 3000), startTime: this.now, remove: false, opacity: 1};
                this.particles.push(p);
              }
        },
        //particles on hover circles
        animateParticles(){
            this.particles.forEach(p => {
                //var timeVal = (1. - ((this.now - p.startTime) / p.lifeTime)); //normalized lifetime
                var timeVal = easeOutExpo((1. - ((this.now - p.startTime) / p.lifeTime)),0,1,1);
                if(timeVal <= 0) p.remove = true;
                p.position.x += p.velocity.vX * timeVal * this.delta;
                p.position.y += (p.velocity.vY * timeVal * this.delta) + (this.gravity * (1.-timeVal));
                //p.position.y += this.gravity * (1.-timeVal);
                p.opacity = timeVal;
            });
            //delete unused particles from list
            this.particles = this.particles.filter((item) => item.remove == false);
        }  
    },
    mounted(){
        //start animation
        this.animate();
    }
}
</script>

<style scoped>
.particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}



</style>
