<template>
    <div>
        <dh-loader v-show="!loaded" class="placeholder"></dh-loader>
        <img v-show="loaded" :srcset="image.srcset" :sizes="this.sizeArray" :src="store.static + image.id" @load="isLoaded"  />
    </div>
</template>

<script>
import dhLoader from '@/components/dhLoader.vue';
import {store} from "@/store";

export default{
    name: "singleImage",
    props: {
        image: Object,
        suppress: Boolean,
        sizeArray: String
    },   
    data() {
        return {
            store,
            loaded: false,
            modal: false
        }
    },
    components: {
        dhLoader,
    },
    methods: {
        isLoaded() {
            this.loaded = true;
            this.$emit('imageLoaded');
        }
    }
}

</script>

<style scoped>
div {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    max-height: 80svh;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: 0 auto
}

.placeholder {
    width: auto;
    max-width: 100%;
    min-height: 75vh;
}

@media screen and (orientation: landscape) {
    img {
        height: 100%;
    }
}

@media screen and (orientation: portrait) {
    img {
        width: 100%
    }
}
</style>