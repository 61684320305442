import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router/router.js';
import vueVimeoPlayer from 'vue-vimeo-player';
import Vue3TouchEvents from "vue3-touch-events";
import VueYtframe from "vue3-ytframe";
import { createHead, VueHeadMixin } from '@unhead/vue';


const app = createApp(App);

app.config.globalProperties.$drafts = true;

app.use(vueVimeoPlayer);
app.use(VueYtframe);
app.use(Vue3TouchEvents);
app.use(createHead());
app.mixin(VueHeadMixin);
app.use(router).mount('#app');

export default app

