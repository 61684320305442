<template>
  <div class="menu" :class="{ maximized: active, minimized: !active }">

    <languageSelect class="langSwitch" />
      
    <h4 class="menupath" @click="activate">
      Menu {{ this.menuText }}
    </h4>

    <socialLinks class="socialLinks" />
      
    <div class="menuNavigation">
      <Transition>
          <dh-navigation v-if="this.$route.path == '/menu'" :routes='routes'></dh-navigation>
      </Transition>
    </div>

    <div class="legal">
      <Transition>
        <button v-if="this.$route.path == '/menu'" class="imprintBtn" @click="openModal()">{{ imprint[store.language] }}</button>
      </Transition>
      <dh-imprint-modal v-if="modal" @close-modal="closeModal()"></dh-imprint-modal>
    </div>
    
    <span class="backgroundMenu" :class="{ nocolor: !active }"></span>
      
  </div>
</template>

<script>
import { store } from '@/store.js';

// Components
import dhNavigation from '@/components/dhNavigation.vue';
import socialLinks from '@/components/socialLinks.vue';
import languageSelect from '@/components/languageSelect.vue';
import dhImprintModal from '@/components/dhImprintModal.vue';

export default {
    name: 'dhMenu',
    data(){
      return{
        store,
        modal: false,
        imprint: {
          'en': 'Imprint',
          'de': 'Impressum'
        }
      }
    },
    components: {
        dhNavigation,
        socialLinks,
        languageSelect,
        dhImprintModal,
    },
  computed: {
      menuText() {
        if (  ['Menu', 'Home', 'CatchAll'].includes(this.$route.name) || this.routes === null) {
          return ''
        }

        return ':: ' + this.routes.find(route => route.id === this.$route.name.replace(/\/$/, '')).title;
      }
  },
  methods: {
      activate() {
          this.$router.push("/menu");
      },
      openModal() {
        this.modal = true;
      },
      closeModal() {
        this.modal = false;
      }
  },
  props: ['active', 'routes']
}
</script>

<style scoped>
div {
    width: 100%;
    transition: height 0.5s ease-in-out;
}

.menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.langSwitch {
  order: 1;
}

.langSwitch, .socialLinks {
  flex: 0 0 20%;
}

.langSwitch, .menupath, .socialLinks, .legal {
  height: 5vh;
  height: 5svh;
}

.backgroundMenu{
  width: 100%;
  height: 100%;
  opacity: 1;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  background-color: mediumspringgreen;
  background-image:
    radial-gradient(ellipse at top left,white 25%, transparent 50%),
    radial-gradient(ellipse at top right,white 25%, transparent 50%),
    radial-gradient(ellipse at bottom left,white 25%, transparent 50%),
    radial-gradient(ellipse at bottom right,white 25%, transparent 50%);
  transition: opacity 0.5s ease;
}

.menupath {
  width: fit-content;
  height: 5vh;
  height: 5svh;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  order: 2;
}

.socialLinks {
  order: 3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.menuNavigation {
  margin: auto 0;
  order: 4
}

.legal {
  order: 5;
  flex: 1 0 fit-content;
  margin-top: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.imprintBtn {
  width: fit-content;
  height: fit-content;
  background: transparent;
  color: slategrey;
  border: none;
  outline: none;
}

.imprintBtn:hover {
  color: fuchsia;
}

.imprintBtn:active {
  color: slategrey;
}

.imprintBtn:visited {
  color: slategrey;
}


.nocolor{
  opacity: 0 !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

p {
  margin: 1rem 0;
}

@media screen and (max-width: 428px) {
  .menupath {order: 1; flex: 1 0 100%;}
  .menuNavigation {order: 2;}
  .langSwitch {order: 3; margin-top: auto; margin-bottom: 1rem}
  .legal {order: 4; flex: 1 0 33%}
  .socialLinks {order: 5; margin-top: auto; margin-bottom: 1rem}
}

@media screen and (min-width: 480px) and (max-width: 768px) and (orientation: landscape) {
  .langSwitch, .menupath, .socialLinks, .legal {
  height: 7.5vh;
  height: 7.55svh;
  }
}
</style>