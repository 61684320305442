<template>
    <p class="text">{{ text }}</p>
</template>

<script>
export default {
    name: 'dhGalleryText',
    props: {
        text: String,
    }
}
</script>

<style scoped>
.text {
    width: fit-content;
    background: white;
    padding: 1rem;
    margin: 1rem auto;
    text-align: center;
}

@media screen and (min-width: 480px) and (max-width: 768px) and (orientation: landscape) {
    .text {
        min-height: calc(50vh - 2rem);
    }
}
</style>